import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: {
      type: "",
      show: false,
      message: "",
      timeout: 2000,
    },
    windowOverlay: {
      show: false,
    },
  },
  mutations: {
    showSnackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.type = payload.type;
      state.snackbar.message = payload.message;
    },
    showOverlay(state) {
      state.windowOverlay.show = true;
    },
    hideOverlay(state) {
      state.windowOverlay.show = false;
    },
  },
  actions: {
    showSnackbar(context, payload) {
      context.commit("showSnackbar", payload);
    },
    showOverlay(context) {
      context.commit("showOverlay");
    },
    hideOverlay(context) {
      context.commit("hideOverlay");
    },
  },
  modules: {},
});
