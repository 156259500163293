import router from "../router";
import jwtDecode from "jwt-decode";

interface User {
  data?: any;
  id: number;
  sub: string;
  name: string;
  username: string;
  email: string;
  phone: string;
  avatar: string;
  cartola: string;
}

class Auth {
  login(token: string) {
    //sessionStorage.token = token;
    sessionStorage.setItem("token", token);

    //const dadosUser = JSON.parse(JSON.stringify(this.getUser()));

    router.push("/");
  }

  getUser(): User {
    return jwtDecode(sessionStorage.token) as User;
  }

  logout() {
    sessionStorage.removeItem("token");

    window.location.reload();
    router.push("/login");
  }
  getUserData() {
    const dados = jwtDecode(sessionStorage.token);
    return dados.data;
  }
  getUserPermissoes() {
    const dados = jwtDecode(sessionStorage.token);
    return dados.permissoes;
  }

  isLogged(): boolean {
    if (sessionStorage.token == undefined || sessionStorage.token == null) {
      return false;
    } else {
      return true;
    }
  }
}
export default new Auth();
